import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import FlipConfigurator from "./components/FlipConfigurator/FlipConfigurator";
import { getToken } from "./helpers";
import Project from "./pages/Projecten/Project/project";
import Projecten from "./pages/Projecten/projecten";
import ReviewPending from "./pages/ReviewPending/review-pending";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";

const AppRoutes = () => {
  const [authToken, setAuthToken] = useState(getToken());

  return (
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/" element={authToken ? <Projecten /> : <SignIn />} />
      <Route path="/project/:projectId" element={<Project />} />
      <Route
        path="/project/:projectId/configurator"
        element={<FlipConfigurator />}
      />
      <Route
        path="/project/:projectId/configurator/:groupId"
        element={<FlipConfigurator />}
      />
      <Route
        path="/project/:projectId/review-pending"
        element={<ReviewPending />}
      />
    </Routes>
  );
};

export default AppRoutes;
