import { Button, Space } from "antd";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CgWebsite, CgLogOut } from "react-icons/cg";
import { useAuthContext } from "../../context/AuthContext";
import { removeToken } from "../../helpers";

const AppHeader = () => {
  const { user, setUser } = useAuthContext();
  const navigate = useNavigate();
  let { projectId } = useParams();
  const [projectNumber, setProjectId] = useState(projectId);

  useEffect(() => {
    if (projectId) {
      setProjectId(projectId);
    }
  }, [projectId]);

  const handleLogout = () => {
    removeToken();
    setUser(undefined);
    navigate("/", { replace: true });
  };

  return (
    <Space className="w-full bg-white h-20  px-10  flex justify-between items-center">
      <div>
        <Link
          to={projectNumber ? `/project/${projectNumber}` : "/"}
          relative="path"
        >
          <svg
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.9976 12.037C22.108 12.2616 22.1809 12.5022 22.2135 12.7494L22.8168 21.7193L23.1162 26.2277C23.1193 26.6913 23.1921 27.152 23.3321 27.5948C23.6938 28.4539 24.5639 29 25.5104 28.9619L39.9346 28.0184C40.5592 28.0081 41.1623 28.2417 41.6114 28.6678C41.9855 29.0229 42.2271 29.4874 42.3032 29.9871L42.3288 30.2905C41.7319 38.5557 35.6615 45.4495 27.4134 47.229C19.1654 49.0086 10.7074 45.2494 6.63153 37.9923C5.4565 35.884 4.72257 33.5666 4.47282 31.1762C4.36849 30.4685 4.32255 29.7538 4.33545 29.0389C4.32257 20.1776 10.6328 12.5168 19.4661 10.6699C20.5292 10.5044 21.5714 11.0672 21.9976 12.037Z"
              fill="#97A5EB"
            />
            <path
              opacity="0.4"
              d="M27.885 4.33515C37.7648 4.5865 46.0683 11.6909 47.6667 21.26L47.6514 21.3306L47.6078 21.4333L47.6139 21.7152C47.5912 22.0886 47.4471 22.4479 47.1986 22.7381C46.9398 23.0404 46.5862 23.2463 46.1968 23.3262L45.9593 23.3588L29.3176 24.437C28.7641 24.4916 28.2129 24.3131 27.8013 23.9459C27.4582 23.64 27.2389 23.2269 27.1769 22.7818L26.0599 6.16434C26.0405 6.10815 26.0405 6.04724 26.0599 5.99105C26.0752 5.533 26.2768 5.10003 26.6198 4.78887C26.9627 4.47772 27.4184 4.31431 27.885 4.33515Z"
              fill="#FFCC91"
            />
          </svg>
        </Link>
      </div>
      <Space className="flex items-center h-full">
        {user ? (
          <>
            <div>{user.email}</div>
            <span
              onClick={handleLogout}
              className="text-primary cursor-pointer"
            >
              log uit
            </span>
          </>
        ) : (
          <>
            <Button className="auth_button_login" href="/signin" type="link">
              Login
            </Button>
            <Button
              className="auth_button_signUp"
              href="/signup"
              type="primary"
            >
              SignUp
            </Button>
          </>
        )}
      </Space>
    </Space>
  );
};

export default AppHeader;
