import { useEffect, useState } from "react";
import { message, Spin } from "antd";
import { API } from "../../constant";
import { useAuthContext } from "../../context/AuthContext";
import { getToken } from "../../helpers";
import { Link, Route } from "react-router-dom";
import Project from "./Project/project";

const Projecten = () => {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(false);
  const { user } = useAuthContext();

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const something = await fetch(`${API}/users/me?populate[0]=project`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const somethingData = await something.json();
      setProject(somethingData?.project);

      message.success("Projecten opgehaald!");
    } catch (error) {
      console.error(Error);
      message.error("Projecten kunnen niet opgehaald worden");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }
  if (!project) {
    return "Geen project toegewezen";
  }

  return (
    <div className="h-screen flex flex-col  justify-center items-center">
      <div className="bg-white px-11 py-9 flex flex-col justify-center items-center rounded-normal">
        <div className="flex flex-col justify-center items-center ">
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.3819 13.8888C25.5092 14.148 25.5933 14.4256 25.631 14.7109L26.3271 25.0607L26.6726 30.2627C26.6762 30.7977 26.7601 31.3292 26.9217 31.8401C27.339 32.8314 28.3429 33.4615 29.4351 33.4175L46.0783 32.3289C46.799 32.317 47.495 32.5866 48.0131 33.0782C48.4449 33.488 48.7236 34.0239 48.8114 34.6004L48.8409 34.9505C48.1522 44.4873 41.1479 52.4417 31.6309 54.495C22.1139 56.5484 12.3547 52.2108 7.65177 43.8373C6.29597 41.4046 5.44913 38.7307 5.16095 35.9725C5.04057 35.156 4.98756 34.3313 5.00245 33.5064C4.98759 23.2819 12.2687 14.4424 22.4608 12.3114C23.6875 12.1204 24.8901 12.7698 25.3819 13.8888Z"
              fill="#97A5EB"
            />
            <path
              opacity="0.4"
              d="M32.175 5.00205C43.5747 5.29207 53.1558 13.4895 55 24.5307L54.9824 24.6122L54.9321 24.7307L54.9391 25.0559C54.913 25.4868 54.7466 25.9014 54.4599 26.2362C54.1613 26.585 53.7533 26.8226 53.304 26.9148L53.03 26.9524L33.828 28.1965C33.1893 28.2595 32.5534 28.0536 32.0784 27.6299C31.6826 27.2768 31.4295 26.8002 31.358 26.2867L30.0692 7.11266C30.0467 7.04782 30.0467 6.97754 30.0692 6.9127C30.0868 6.38418 30.3194 5.8846 30.7151 5.52557C31.1108 5.16655 31.6366 4.978 32.175 5.00205Z"
              fill="#FFCC91"
            />
          </svg>
          <h1>Schakelaar configuratie</h1>
        </div>

        <div>
          <h3 className="font-normal">{project.naam}</h3>
        </div>

        <Link to={`/project/${project.id}`}>
          <div className="button-primary">Starten</div>
        </Link>
      </div>
    </div>
  );
};

export default Projecten;
