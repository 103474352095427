import React from "react";
import { Col, Layout, Row } from "antd";
import AppHeader from "./components/Appheader/Appheader";
import AppRoutes from "./Routes";
const { Header, Content } = Layout;

const App = () => {
  return (
    <div className="bg-slate-200 min-h-screen">
      <AppHeader />
      <div className="container mx-auto px-5 md:px-12 lg:px-10 xl:px-24 2xl:px-48 mt-10 main-section">
        <AppRoutes />
      </div>
    </div>
  );
};

export default App;
