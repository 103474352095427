import React from "react";
import { Field } from "react-final-form";
import { motion } from "framer-motion";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};
function ChooseGroupType() {
  return (
    <div className="flex w-full justify-center">
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className="flex flex-col justify-center w-80"
      >
        <motion.div variants={item} className="py-4 w-full">
          <div className="mb-4">Adres</div>
          <Field
            name="groupNumber"
            className="rounded-md py-2 bg-background/[.6] border-none w-full placeholder-gray-300 px-2"
            component="input"
            placeholder="1.50.0.1"
          />
        </motion.div>
        <motion.div variants={item} className="py-4 w-full">
          <div className="mb-4">Beschrijving</div>
          <Field
            name="beschrijving"
            className="rounded-md py-2 bg-background/[.6] border-none w-full placeholder-gray-300 px-2"
            component="input"
            placeholder="Let op voor..."
          />
        </motion.div>
        <motion.div variants={item} className="py-4 w-full">
          <div className="mb-4">Ruimte</div>
          <Field
            name="ruimte"
            className="rounded-md py-2 bg-background/[.6] border-none w-full placeholder-gray-300 px-2"
            component="input"
            placeholder="Living"
          />
        </motion.div>
      </motion.div>
    </div>
  );
}

export default ChooseGroupType;
