import { Button, message, Modal, Steps } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Form, useForm } from "react-final-form";
import { useParams, useNavigate } from "react-router-dom";
import { API, BEARER } from "../../constant";
import { getToken } from "../../helpers";
import {
  sanitizeGroupValues,
  deSanitizeGroupValues,
} from "../../utils/sanitize";
import ChooseFunctionalities from "./ChooseFunctionalities";
import ChooseGroupType from "./ChooseGroupType";
import ChooseSchakelaarType from "./ChooseSchakelaarType";
import { motion, AnimatePresence } from "framer-motion";

const statusCheck = (current, number) => {
  if (number === current) {
    return "process";
  }
  if (number < current) {
    return "finish";
  }
  return "wait";
};

const FlipConfigurator = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [existingGroup, setExistingGroup] = useState(false);

  const { projectId, groupId } = useParams();
  const navigate = useNavigate();

  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: "Informatie",
      content: <ChooseGroupType />,
      status: statusCheck(current, 0),
    },
    {
      title: "Type Kiezen",
      content: <ChooseSchakelaarType />,
      status: statusCheck(current, 1),
    },
    {
      title: "Functionaliteit",
      content: <ChooseFunctionalities />,
      status: statusCheck(current, 2),
    },
  ];
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const authToken = getToken();

  useEffect(() => {
    fetchExistingGroup(groupId);
  }, [groupId]);

  const fetchExistingGroup = async (groupId) => {
    try {
      const response = await fetch(
        `${API}/schakelaarsgroepen/${groupId}?populate[schakelaarstype][populate][0]=image`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // set the auth token to the user's jwt
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      const responseData = await response.json();
      setExistingGroup(responseData.data);
    } catch (error) {
      console.error(error);
      message.error("schakelaarsgroepen kunnen niet opgehaald worden");
    }
  };

  const submitNewGroup = async (values) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/schakelaarsgroepen?populate[functionaliteiten][populate][0]=functionaliteit`,
        {
          method: "POST",
          headers: {
            Authorization: `${BEARER} ${authToken}`,

            "Content-Type": "application/json",
          },
          body: JSON.stringify(sanitizeGroupValues(values, projectId)),
        }
      );

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        message.success(`groep aangemaakt`);
        navigate(`project/${projectId}`, { replace: true });
      }
    } catch (error) {
      console.error(error);
      // setError(error?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };
  const initialValues = useMemo(
    () => deSanitizeGroupValues(existingGroup) ?? {},
    [existingGroup]
  );
  console.log(initialValues);
  return (
    <div className="h-full w-full">
      <AnimatePresence mode="wait">
        <ModalBackToList />
        <div className="bg-white rounded-normal h-full w-full">
          <Form
            keepDirtyOnReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values) => submitNewGroup(values)}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({ handleSubmit, values }) => (
              <div className="flex flex-col justify-center relative h-full">
                {values.groupNumber && current > 0 && (
                  <div className="flex justify-center items-center w-full absolute top-0 mt-5   py-4 ">
                    <h1 className="text-2xl font-bold">{values.groupNumber}</h1>
                  </div>
                )}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="steps-content"
                >
                  {steps[current].content}
                </motion.div>
                <div className="steps-action flex w-full absolute bottom-2 justify-between items-center px-24 mb-8">
                  {current > 0 ? (
                    <Button
                      style={{ margin: "0 8px" }}
                      onClick={() => prev()}
                      className="button-primary"
                    >
                      Previous
                    </Button>
                  ) : (
                    <div />
                  )}
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex justify-center items-center w-full px-12"
                  >
                    <Steps
                      size="small"
                      className=""
                      current={current}
                      items={steps}
                    />
                  </motion.div>

                  {current === 0 && (
                    <Button
                      onClick={() => next()}
                      className="button-primary self-center"
                    >
                      Next
                    </Button>
                  )}

                  {current === 1 && (
                    <Button
                      disabled={!values.chosenSwitch?.aantalKnoppen}
                      onClick={() => next()}
                      className="button-primary self-center"
                    >
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button className="button-primary" onClick={handleSubmit}>
                      save
                    </Button>
                  )}
                </div>
              </div>
            )}
          />
        </div>
      </AnimatePresence>
    </div>
  );
};

const ModalBackToList = () => {
  const { projectId, groupId } = useParams();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    navigate(`/project/${projectId}`);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="pb-3">
        <span onClick={showModal} className="text-primary cursor-pointer">
          terug naar lijst
        </span>
      </div>
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          Uw voortgang is niet opgeslagen, zeker dat u terig wil naar de lijst?
        </p>
      </Modal>
    </>
  );
};

export default FlipConfigurator;
