import React from "react";
import { useEffect, useState } from "react";
import { Button, Dropdown, message, Spin } from "antd";
import { API } from "../../constant";
import { getToken } from "../../helpers";
import staticFunctionalities from "../../data/functionalities";
import { Field, useForm, useField } from "react-final-form";
import Condition from "../Forms/Condition";
import Select from "react-select";
import {
  AiFillCheckCircle,
  AiOutlinePlus,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { CgSpinner } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { Tooltip, TooltipPopup } from "@reach/tooltip";

const ReactSelectAdapter = ({ input, ...rest }) => (
  <Select {...input} {...rest} />
);

const Radio = ({ input, children }) => (
  <label>
    <input type="radio" {...input} />
    {children}
  </label>
);

const ConditionalFunctionalities = ({ currentNumberEditing, index }) => {
  return (
    <div className="divide-y flex flex-col justify-center gap-y-4 divide-slate-900">
      <h1>knop: {currentNumberEditing}</h1>
      <div className="flex flex-col">
        <label>functionaliteit</label>
        <Field
          name={`functionalities[${index}].switchFunctionality`}
          component={ReactSelectAdapter}
          options={staticFunctionalities.map((functionality) => ({
            value: functionality.name,
            label: functionality.name,
          }))}
        />
      </div>
      <Condition
        when={`functionalities[${index}].switchFunctionality.value`}
        is="switch"
      >
        <div className="flex flex-col">
          <label>functionaliteit optie</label>

          <Field
            name={`functionalities[${index}].switchFunctionality.option`}
            component={ReactSelectAdapter}
            options={staticFunctionalities[0].options.map((functionality) => ({
              value: functionality.name,
              label: functionality.name,
            }))}
          />
        </div>{" "}
        <div className="flex flex-col">
          <label>broncode</label>

          <Field
            name={`functionalities[${index}].switchFunctionality.bron`}
            className="rounded-md py-2 bg-accent/[.2] border-none block"
            component="input"
          />
        </div>
      </Condition>
      <Condition
        when={`functionalities[${index}].switchFunctionality.value`}
        is="Switch en Dim"
      >
        <label className="flex">
          broncode
          <AiOutlineInfoCircle className="text-primary" />
        </label>

        <Field
          name={`functionalities[${index}].switchFunctionality.bron`}
          className="rounded-md py-2 bg-accent/[.2] border-none block"
          component="input"
        />
      </Condition>
      <Condition
        when={`functionalities[${index}].switchFunctionality.value`}
        is="Shutter"
      >
        <label>broncode</label>

        <Field
          name={`functionalities[${index}].switchFunctionality.bron`}
          className="rounded-md py-2 bg-accent/[.2] border-none block"
          component="input"
        />
      </Condition>
      <Condition
        when={`functionalities[${index}].switchFunctionality.value`}
        is="Value Operation"
      >
        <div>
          SHORT
          <Field
            name={`functionalities[${index}].switchFunctionality.short`}
            component={ReactSelectAdapter}
            options={staticFunctionalities[3].options.map((functionality) => ({
              value: functionality.name,
              label: functionality.name,
            }))}
          />
          <label>broncode</label>
          <Field
            name={`functionalities[${index}].switchFunctionality.short.bronShort`}
            className="rounded-md py-2 bg-accent/[.2] border-none block"
            component="input"
          />
          <Condition
            when={`functionalities[${index}].switchFunctionality.short.value`}
            is="Bewegingsmelder"
          >
            <div className="flex flex-col">
              <label> Gedrag bij blokkage </label>
              <Field
                name={`functionalities[${index}].switchFunctionality.short.bewegingsmelder`}
                type="radio"
                value="yes"
                component={Radio}
              >
                Yes
              </Field>
              <Field
                name={`functionalities[${index}].switchFunctionality.short.bewegingsmelder`}
                type="radio"
                value="no"
                component={Radio}
              >
                No
              </Field>
            </div>
          </Condition>
        </div>
        <div>
          LONG
          <Field
            name={`functionalities[${index}].switchFunctionality.long`}
            component={ReactSelectAdapter}
            options={staticFunctionalities[3].options.map((functionality) => ({
              value: functionality.name,
              label: functionality.name,
            }))}
          />
          <label>broncode</label>
          <Field
            name={`functionalities[${index}].switchFunctionality.long.bronLong`}
            className="rounded-md py-2 bg-accent/[.2] border-none block"
            component="input"
          />
          <Condition
            when={`functionalities[${index}].switchFunctionality.long.value`}
            is="Bewegingsmelder"
          >
            <div className="flex flex-col">
              <label> Gedrag bij blokkage </label>

              <Field
                name={`functionalities[${index}].switchFunctionality.long.bewegingsmelder`}
                type="radio"
                value="yes"
                className="rounded-md py-2 bg-accent/[.2] border-none block"
                component={Radio}
              >
                Yes
              </Field>
              <Field
                name={`functionalities[${index}].switchFunctionality.long.bewegingsmelder`}
                type="radio"
                value="no"
                className="block"
                component={Radio}
              >
                No
              </Field>
            </div>
          </Condition>
        </div>
      </Condition>
      <Condition
        when={`functionalities[${index}].switchFunctionality.value`}
        is="Andere"
      >
        <label>gewenste functionaliteit</label>
        <Field
          name={`functionalities[${index}].switchFunctionality.bron`}
          className="rounded-md py-2 bg-accent/[.2] border-none"
          component="textarea"
        />
      </Condition>
    </div>
  );
};

const FunctionalityButton = ({ functionality, onClick, keyNumberIndex }) => {
  const formValues = useForm().getState().values;
  return (
    <motion.div
      onClick={onClick}
      className={`flex flex-col w-36 justify-center cursor-pointer items-center ${functionality.backgroundColor} ${functionality.color} border-${functionality.color}-600 border-solid border rounded-normal px-8 py-2`}
    >
      <span className="flex justify-center items-center">
        {functionality.text} {React.createElement(functionality.icon)}
      </span>
      {functionality.legendText === "compleet" && (
        <div className="flex flex-col font-semibold">
          {formValues.functionalities[keyNumberIndex]?.switchFunctionality
            ?.label && (
            <div>
              {
                formValues.functionalities[keyNumberIndex]?.switchFunctionality
                  ?.label
              }
            </div>
          )}
          {formValues.functionalities[keyNumberIndex]?.switchFunctionality
            ?.bron && (
            <div>
              {
                formValues.functionalities[keyNumberIndex]?.switchFunctionality
                  ?.bron
              }
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
};

function ChooseFunctionalities() {
  const differentButtonTypes = {
    complete: {
      color: "text-green-600",
      backgroundColor: "bg-green-200",
      text: "Compleet",
      icon: AiFillCheckCircle,
      legendText: "compleet",
    },
    inProgress: {
      color: "text-blue-600",
      backgroundColor: "bg-blue-200",
      text: "In bewerking",
      icon: CgSpinner,
      legendText: "aan het toewijzen...",
    },
    toDo: {
      color: "text-red-600",
      backgroundColor: "bg-red-200",
      text: "To Do",
      icon: AiOutlinePlus,
      legendText: "To Do",
    },
  };
  const [loading, setLoading] = useState(false);
  const [functionalities, setFunctionalities] = useState(false);
  const [currentNumberEditing, setcurrentNumberEditing] = useState(false);

  const formValues = useForm().getState().values;
  const gekozenSchakelaarType = formValues.chosenSwitch;
  const aantalKnoppen = formValues.chosenSwitch?.aantalKnoppen?.value;

  const fetchFunctionalities = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/functionaliteiten`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      setFunctionalities(responseData?.data);
    } catch (error) {
      console.error(Error);
      message.error("schakelaars kunnen niet opgehaald worden");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchFunctionalities();
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }

  console.log(aantalKnoppen);
  const amountButtonsPerSide = new Array(Math.round(aantalKnoppen / 2)).fill(0);

  return (
    <div className="flex">
      <div className="flex flex-col p-10">
        {gekozenSchakelaarType && (
          <div className="flex justify-center h-full">
            <div className="flex flex-col min-h-full py-8 justify-between items-stretch">
              <AnimatePresence mode="wait">
                {amountButtonsPerSide.map((_, index) => (
                  <FunctionalityButton
                    onClick={() => setcurrentNumberEditing(index + 1)}
                    keyNumberIndex={index}
                    functionality={
                      currentNumberEditing === index + 1
                        ? differentButtonTypes.inProgress
                        : formValues.functionalities &&
                          formValues.functionalities[index]
                        ? differentButtonTypes.complete
                        : differentButtonTypes.toDo
                    }
                  />
                ))}
              </AnimatePresence>
            </div>
            <img
              src={
                API.substring(0, API.length - 4) +
                gekozenSchakelaarType.attributes.image.data.attributes.url
              }
            />
            <div className="flex flex-col min-h-full py-8 justify-between items-stretch">
              {amountButtonsPerSide.map((_, index) => (
                <FunctionalityButton
                  onClick={() =>
                    setcurrentNumberEditing(aantalKnoppen / 2 + index + 1)
                  }
                  keyNumberIndex={aantalKnoppen / 2 + index}
                  functionality={
                    currentNumberEditing === aantalKnoppen / 2 + index + 1
                      ? differentButtonTypes.inProgress
                      : formValues.functionalities &&
                        formValues.functionalities[index + aantalKnoppen / 2]
                      ? differentButtonTypes.complete
                      : differentButtonTypes.toDo
                  }
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <AnimatePresence mode="wait">
        {currentNumberEditing && (
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: 10 }}
            exit={{ opacity: 0, x: 0 }}
            className=" w-1/3 border-l border-solid border-blue-200 h-full absolute right-0 px-4 top-0 py-8 bg-slate-100 z-40"
          >
            <div className="flex w-full justify-end py-4 text-xl">
              <IoMdClose
                className="cursor-pointer"
                onClick={() => setcurrentNumberEditing(null)}
              />
            </div>
            <ConditionalFunctionalities
              currentNumberEditing={currentNumberEditing}
              index={currentNumberEditing - 1}
            />
            <Button
              className="button-primary"
              onClick={() => setcurrentNumberEditing(false)}
            >
              Opslaan
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ChooseFunctionalities;
