const staticFunctionalities = [
  {
    id: 1,
    name: "switch",
    options: [
      {
        name: "Aan",
        textInput: true,
      },
      {
        name: "Uit",
        textInput: true,
      },
      {
        name: "Aan/Uit",
        textInput: true,
      },
    ],
  },
  {
    id: 2,

    name: "Switch en Dim",
    options: [
      {
        name: "waarde",
        textInput: true,
      },
    ],
  },
  {
    id: 3,

    name: "Shutter",
    options: [
      {
        name: "waarde",
        textInput: true,
      },
    ],
  },
  {
    id: 4,

    name: "Value Operation",
    options: [
      {
        name: "Aan",
        textInput: true,
      },
      {
        name: "Uit",
        textInput: true,
      },
      {
        name: "Aan/Uit",
        textInput: true,
      },
      {
        name: "Bewegingsmelder",
        textInput: true,
        radioInput: true,
      },
    ],
  },
  {
    id: 5,

    name: "Andere",
    options: [
      {
        name: "Aan",
        textArea: true,
      },
    ],
  },
];

const mapFunctionalities = {};

export default staticFunctionalities;
