import Select from "react-select";

const SelectAdapter = ({ input, ...rest }) => {
  return (
    <>
      <label>{rest.label}</label>
      <Select {...input} {...rest} />
    </>
  );
};

export default SelectAdapter;
