import { message, Modal, Space, Table, Button } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { API } from "../../../constant";
import { getToken } from "../../../helpers";

function Project() {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [project, setProject] = useState(false);

  const { pathname } = useLocation();
  let { projectId } = useParams();
  const fetchGroups = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API}/projecten/${projectId}?populate[schakelaarsgroepen][populate][0]=schakelaarstype`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // set the auth token to the user's jwt
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      const responseData = await response.json();
      setGroups(responseData?.data?.attributes?.schakelaarsgroepen?.data);
    } catch (error) {
      console.error(Error);
      message.error("schakelaarsgroepen kunnen niet opgehaald worden");
    } finally {
      setLoading(false);
    }
  };

  const fetchProject = async () => {
    setLoading(true);
    try {
      const something = await fetch(`${API}/users/me?populate[0]=project`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const somethingData = await something.json();
      setProject(somethingData?.project);
    } catch (error) {
      console.error(Error);
      message.error("Projecten kunnen niet opgehaald worden");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
    fetchProject();
  }, []);
  if (loading) {
    return <div></div>;
  }
  const columns = [
    {
      title: "adres",
      dataIndex: "adres",
      key: "adres",
    },
    {
      title: "ruimte",
      dataIndex: "ruimte",
      key: "ruimte",
      sorter: (a, b) => a.ruimte < b.ruimte,
    },
    {
      title: "beschrijving",
      dataIndex: "beschrijving",
      key: "beschrijving",
    },
    {
      title: "opmerking",
      dataIndex: "opmerking",
      key: "opmerking",
    },
    {
      title: "schakelaar",
      dataIndex: "typeSchakelaar",
      key: "typeSchakelaar",
    },
    {
      title: "aantal knoppen",
      dataIndex: "aantalKnoppen",
      key: "aantalKnoppen",
    },
    {
      title: "settings",
      key: "settings",
      render: (_, record) => {
        return project.isReviewNeeded ? (
          <div>In review</div>
        ) : (
          <Space size="middle">
            <Link to={`${pathname}/configurator/${record.id}`} relative="path">
              Settings
            </Link>
          </Space>
        );
      },
    },
  ];
  return (
    <div className="">
      <div className="bg-white px-11 py-9  rounded-normal">
        {groups && (
          <Table
            dataSource={groups.map((group) => ({
              ...group.attributes,
              id: group.id,
              typeSchakelaar:
                group.attributes.schakelaarstype.data.attributes.naam,
            }))}
            columns={columns}
          />
        )}
        <div className="flex w-full justify-end">
          <Button
            className="button-primary w-64"
            onClick={() => setShowModal(true)}
            type="primary"
            disabled={project.isReviewNeeded}
          >
            <Link to={`${pathname}/configurator`} relative="path">
              Groep Toevoegen
            </Link>
          </Button>
        </div>
      </div>

      <div className="flex w-full justify-end">
        <Button
          className="button-primary w-full"
          onClick={() => setShowModal(true)}
          type="primary"
          disabled={project.isReviewNeeded}
        >
          Review Aanvragen
        </Button>
      </div>
      <Modal open={showModal} onCancel={() => setShowModal(false)}>
        <SubmitForReview />
      </Modal>
    </div>
  );
}

const SubmitForReview = () => {
  const [loading, setLoading] = useState(false);
  let { projectId } = useParams();
  const navigate = useNavigate();

  const askReview = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/projecten/${projectId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ data: { isReviewNeeded: true } }),
      });
    } catch (error) {
      console.error(Error);
      message.error("Review kon niet worden aangevraagd");
    } finally {
      setLoading(false);
      message.success("Review aangevraagd");
      navigate(`/project/${projectId}/review-pending`, { replace: true });
    }
  };

  if (loading) {
    return <div></div>;
  }
  return (
    <div className="mt-10">
      <div className="bg-white px-11 py-9  rounded-normal">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="text-2xl font-bold">
              Ben je zeker? Alles overlezen?
            </div>
            <div className="text-2xl mt-4">
              De waarden kunnen niet meer aangepast worden!
            </div>
            <div className="button-primary w-full" onClick={askReview}>
              Ik ben zeker!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
