import React, { useContext } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { AuthContext, useAuthContext } from "../../context/AuthContext";

function ReviewPending() {
  const { user } = useAuthContext();

  return (
    <div className="bg-white rounded-normal px-4 py-8 flex justify-center items-center mt-8">
      <div className="w-96">
        Uw aanvraag is ingediend. U zal in de nabije toekomst hierover een mail
        krijgen op het adres <b>{user?.email}</b>
      </div>
      <div>
        <AiFillCheckCircle className="text-7xl text-green-800" />
      </div>
    </div>
  );
}

export default ReviewPending;
