import { Carousel, message } from "antd";
import React, { useEffect, useState } from "react";
import { Field, useForm } from "react-final-form";
import { API } from "../../constant";
import { getToken } from "../../helpers";
import SelectAdapter from "../Forms/SelectAdapter";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={"absolute top-1/2 right-0 cursor-pointer"}
      style={{ ...style, fontSize: "80px" }}
      onClick={onClick}
    >
      <BsChevronRight className="mr-24" color="black" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={"absolute top-1/2 left-0 cursor-pointer z-40"}
      style={{ ...style, fontSize: "80px" }}
      onClick={onClick}
    >
      <BsChevronLeft height="32" width="32px" className="ml-24" color="black" />
    </div>
  );
}

const ChooseSchakelaarType = () => {
  const [loading, setLoading] = useState(false);
  const [schakelaarTypes, setSchakelaarTypes] = useState(false);
  const [currentSchakelaarTypeVarianten, setCurrentSchakelaarTypeVarianten] =
    useState(false);
  const fetchSchakelaarTypes = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/schakelaarstypes?populate=%2A`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      setSchakelaarTypes(responseData?.data);
      something.mutators.setValue("chosenSwitch", schakelaarTypes[0]);
    } catch (error) {
      console.error(Error);
      message.error("schakelaars kunnen niet opgehaald worden");
    } finally {
      setLoading(false);
    }
  };
  const form = useForm();
  const something = useForm();
  useEffect(() => {
    fetchSchakelaarTypes();
  }, []);

  useEffect(() => {
    something.mutators.setValue("chosenSwitch", schakelaarTypes[0]);

    setCurrentSchakelaarTypeVarianten(
      schakelaarTypes[0]?.attributes.Variaties.map((v) => ({
        value: v.aantalToetsen,
        label: v.aantalToetsen,
      }))
    );
  }, [schakelaarTypes]);

  if (!schakelaarTypes) {
    return <div></div>;
  }

  const settings = {
    arrows: true,
    dots: false,
    nextArrow: (
      <SampleNextArrow className="mr-12 text-red-800 absolute text-6xl w-12" />
    ),
    prevArrow: <SamplePrevArrow />,
  };

  const onChange = (currentSlide) => {
    something.mutators.setValue("chosenSwitch", schakelaarTypes[currentSlide]);
    setCurrentSchakelaarTypeVarianten(
      schakelaarTypes[currentSlide].attributes.Variaties.map((v) => ({
        value: v.aantalToetsen,
        label: v.aantalToetsen,
      }))
    );
  };

  return (
    <div className="text-black w-full h-full overflow-visible">
      <Carousel
        {...settings}
        afterChange={onChange}
        className="h-full relative overflow-visible"
      >
        {schakelaarTypes
          ?.map((schakelaarType) => schakelaarType?.attributes)
          .map(({ naam, beschrijving, image }) => (
            <div>
              <div className="flex flex-col w-full justify-center items-center relative h-full z-10 overflow-visible">
                <h1 className="font-light mb-12">{naam}</h1>

                <img
                  src={
                    API.substring(0, API.length - 4) + image.data.attributes.url
                  }
                />
                {currentSchakelaarTypeVarianten && (
                  <Field
                    name="chosenSwitch.aantalKnoppen"
                    component={SelectAdapter}
                    options={currentSchakelaarTypeVarianten}
                    className="overflow-visible min-w-fit  text-primary focus:border-none mt-4 z-50 border-none!important border-hidden"
                    style={{
                      backgroundColor: "red",
                    }}
                    placeholder="Aantal knoppen"
                  />
                )}

                <div className="text-center text-accent mt-8">
                  {beschrijving}
                </div>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default ChooseSchakelaarType;
