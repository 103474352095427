export const sanitizeGroupValues = (values, projectId) => {
  return {
    data: {
      adres: values.groupNumber,
      beschrijving: values.beschrijving,
      ruimte: values.ruimte,
      schakelaarstype: values.chosenSwitch.id,
      aantalKnoppen: values.chosenSwitch.aantalKnoppen,
      project: projectId,
      functionaliteiten: values.functionalities,
    },
  };
};

export const deSanitizeGroupValues = (value) => {
  return {
    groupNumber: value?.attributes?.adres,
    beschrijving: value?.attributes?.beschrijving,
    ruimte: value?.attributes?.ruimte,
    chosenSwitch: {
      aantalKnoppen: {
        value: value?.attributes?.aantalKnoppen,
        label: value?.attributes?.aantalKnoppen,
      },
      ...value?.attributes?.schakelaarstype.data,
    },
    functionalities: value?.attributes?.functionaliteiten,
  };
};
